import { Button, Container, Heading, Stack, Text } from '@chakra-ui/react'
import { NextSeo } from 'next-seo'

const NotFoundPage = () => {
  return (
    <>
      <NextSeo title='Page not found' />
      <Container py={20} textAlign='center'>
        <Stack align='center' spacing={8}>
          <Heading>Oh no!</Heading>
          <Text>Page not found</Text>
          <Button as='a' href='/' colorScheme='red'>
            Back to the homepage
          </Button>
        </Stack>
      </Container>
    </>
  )
}

export async function getStaticProps() {
  return {
    props: {},
    revalidate: 2,
  }
}

export default NotFoundPage
